<template>
    <div class="pattern-selector">
        <div v-for="option in options" :key="option.Id" :class="[option.selected === true ? 'is-selected' : 'not-selected']" @click="Select(option)">
            <figure class="image is-clickable mx-3 my-3">
                <span :style="{ 'font-size': fontSize }">{{option.Name}}</span>
            </figure>
        </div>
        <div v-if="edit">
            <o-icon icon="plus-square" pack="far" size="large" clickable @click="$emit('add')"></o-icon>
        </div>
    </div>
</template>
<script>
    import { getCurrentInstance, ref, computed, watch, onMounted, inject } from "vue";
    export default {
        props: {
            modelValue: {
                type: [Object, Array],
                default: () => { return null; }
            },
            multi: {
                type: Boolean,
                default: false
            },
            styleId: {
                type: [String, Number],
                default: ''
            },
            openAir: {
                type: Boolean,
                default: false
            },
            edit: {
                type: Boolean,
                default: false
            },
            size: {
                type: [String, Number],
                default: 13
            }
        },
        emits: ["update:modelValue", "init"],
        setup(props, context) {
            const global = getCurrentInstance()?.appContext.config.globalProperties;
            const $http = inject("$http");
            const $moment = inject("$moment");
            const $success = inject("$success");
            const $error = inject("$error");

            const api = ref(process.env.VUE_APP_API);
            const options = ref([]);

            const CheckModelToSelect = (model) => {
                if (options.value.length === 1) {
                    options.value[0].selected = true;
                    return;
                }
                if (props.multi) {
                    for (var k in model) {
                        const item = model[k];
                        for (var j in options.value) {
                            if (props.openAir) {
                                if (options.value[j].PatternId === item.PatternId && options.value[j].SizeId === item.SizeId && options.value[j].DetailId === item.DetailId)
                                    options.value[j].selected = true;
                            }
                            else {
                                if (options.value[j].Id === item.Id)
                                    options.value[j].selected = true;
                            }
                        }
                    }
                }
                else {
                    for (var i in options.value) {
                        if (props.openAir) {
                            if (options.value[i].PatternId === model.PatternId && options.value[i].SizeId === model.SizeId && options.value[i].DetailId === model.DetailId)
                                options.value[i].selected = true;
                            else
                                options.value[i].selected = false;
                        }
                        else {
                            if (options.value[i].Id === model.Id)
                                options.value[i].selected = true;
                            else
                                options.value[i].selected = false;
                        }
                    }
                }
            };

            const GetOptions = async (src) => {
                try {
                    var response = await $http.get(src);
                    options.value = response.data;
                    CheckModelToSelect(props.modelValue);
                }
                catch (err) {
                    console.error(err);
                }
            }

            const fontSize = computed(() => {
                if (!isNaN(props.size))
                    return props.size + "em";
                else
                    return props.size;
            });

            watch(() => props.styleId, () => {
                if (!props.styleId) {
                    options.value = [];
                    return;
                }

                if (props.openAir) {
                    GetOptions("/api/grilleStyleType/" + props.styleId + "/patterns/dimensions");
                }
                else {
                    GetOptions("/api/grilleStyleType/" + props.styleId + "/patterns/sizes");
                }
            });

            if (props.styleId) {
                if (props.openAir) {
                    GetOptions("/api/grilleStyleType/" + props.styleId + "/patterns/dimensions");
                }
                else {
                    GetOptions("/api/grilleStyleType/" + props.styleId + "/patterns/sizes");
                }
            }

            watch(() => props.modelValue, (n, o) => {
                if (n != o) {
                    CheckModelToSelect(n);
                }
            });
            CheckModelToSelect(props.modelValue);

            const CreateModel = () => {
                var model = options.value.filter(x => x.selected === true);
                if (!props.multi)
                    model = model[0];
                context.emit("update:modelValue", model);
            };

            const Select = (option) => {
                if (props.multi) {
                    option.selected = !option.selected;
                }
                else {
                    for (var i = 0; i < options.value.length; i++) {
                        options.value[i].selected = false;
                    }
                    option.selected = true;
                }
                CreateModel();
            };

            context.emit("init", {
                Reset: () => {
                    for (var i = 0; i < options.value.length; i++) {
                        options.value[i].selected = false;
                    }
                    CreateModel();
                }
            });

            return {
                api,
                options,
                fontSize,
                CreateModel,
                Select
            };
        }
    }

</script>