<template>
    <div class="section">
        <div class="container">

            <h2 class="title is-1">Open Area Perforated Calculator</h2>

            <o-steps v-model="step" :has-navigation="false" rounded animated>
                <o-step-item step="1" label="Selection" icon="object-group" :clickable="false">
                    <div>
                        <o-collapse class="card my-2" animation="slide">
                            <template v-slot:trigger="props" role="button">
                                <div class="card-header">
                                    <p class="card-header-title">
                                        Style <span class="card-header-subtitle mx-2" v-show="!props.open">{{subTitleStyle}}</span>
                                    </p>
                                    <a class="card-header-icon"><o-icon :icon="props.open ? 'caret-up' : 'caret-down'"></o-icon></a>
                                </div>
                            </template>
                            <div class="card-content">
                                <div class="content">
                                    <image-selector v-model="style" :data="options" :size="8"></image-selector>
                                    <div class="has-text-info has-text-centered my-3" v-show="!style?.Id">
                                        Select a style
                                    </div>
                                </div>
                            </div>
                        </o-collapse>

                        <o-collapse class="card my-2" animation="slide" :open="!!style?.Id">
                            <template v-slot:trigger="props" role="button">
                                <div class="card-header">
                                    <p class="card-header-title">
                                        Pattern Size <span class="card-header-subtitle mx-2" v-show="!props.open">{{subTitlePatternSize}}</span>
                                    </p>
                                    <a class="card-header-icon"><o-icon :icon="props.open ? 'caret-up' : 'caret-down'"></o-icon></a>
                                </div>
                            </template>
                            <div class="card-content">
                                <div class="content">
                                    <pattern-selector v-model="patternSize" :styleId="style.Id" :size="2"></pattern-selector>
                                    <div class="has-text-info has-text-centered my-3" v-show="!patternSize?.Id">
                                        Select a pattern size
                                    </div>
                                </div>
                            </div>
                        </o-collapse>

                        <o-collapse class="card my-2" animation="slide" :open="!!patternSize?.Name">
                            <template v-slot:trigger="props" role="button">
                                <div class="card-header">
                                    <p class="card-header-title">
                                        Dimensions <span class="card-header-subtitle mx-2" v-show="!props.open">{{subTitleDim}}</span>
                                    </p>
                                    <a class="card-header-icon"><o-icon :icon="props.open ? 'caret-up' : 'caret-down'"></o-icon></a>
                                </div>
                            </template>
                            <div class="card-content">
                                <div class="content">
                                    <div class="columns">
                                        <div class="column is-12">
                                            <div>
                                                <image-component name="perforated-dimension-calculator1.jpg" alt="Perforated Dimension Reference" size="24"></image-component>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="columns">
                                        <div class="column is-6">
                                            <o-field label="[A] Grille Height w/ Border (in)">
                                                <o-input v-model="height"></o-input>
                                            </o-field>
                                        </div>
                                        <div class="column is-6">
                                            <o-field label="[B] Grille Length w/ Border (in)">
                                                <o-input v-model="length"></o-input>
                                            </o-field>
                                        </div>
                                    </div>
                                    <div class="columns">
                                        <div class="column is-6">
                                            <o-field label="[H] Opening Height (in)">
                                                <o-input v-model="openHeight"></o-input>
                                            </o-field>
                                        </div>
                                        <div class="column is-6">
                                            <o-field label="[L] Opening Length (in)">
                                                <o-input v-model="openLength"></o-input>
                                            </o-field>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </o-collapse>

                        <div class="has-text-centered my-5">
                            <o-button variant="danger" class="mx-2" @click="Reset">Reset</o-button>
                            <o-button variant="primary" icon-left="calculator" class="mx-2" :disabled="!isValid" @click="Calculate1">Next</o-button>
                        </div>
                    </div>
                </o-step-item>
                <o-step-item step="2" label="Size" icon="ruler-combined" :clickable="false">
                    <div>
                        <o-collapse class="card my-2" animation="slide">
                            <template v-slot:trigger="props" role="button">
                                <div class="card-header">
                                    <p class="card-header-title">
                                        Border Size <span class="card-header-subtitle mx-2" v-show="!props.open">{{subTitleBorder}}</span>
                                    </p>
                                    <a class="card-header-icon"><o-icon :icon="props.open ? 'caret-up' : 'caret-down'"></o-icon></a>
                                </div>
                            </template>
                            <div class="card-content">
                                <div class="content">
                                    <div class="columns">
                                        <div class="column is-12">
                                            <div>
                                                <image-component name="perforated-dimension-calculator2.jpg" alt="Perforated Dimension Reference" size="24"></image-component>
                                            </div>
                                            <div>
                                                <p>
                                                    Please note: this calculator does not include any border sizes greater than 2 inches. If you need a border size greater than 2 inches, please contact sales.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="columns">
                                        <div class="column is-12">
                                            <h3 class="title is-3">[A] Border Height</h3>
                                            <p>
                                                Please choose one approximately preferred border size along the height
                                            </p>
                                            <text-selector v-model="borderHeight" :data="optionBordersH"></text-selector>
                                            <div class="has-text-info has-text-centered my-3" v-show="!borderHeight?.Id">
                                                Select a border height
                                            </div>

                                            <h3 class="title is-3">[B] Border Length</h3>
                                            <p>
                                                Please choose one approximately preferred border size along the length
                                            </p>
                                            <text-selector v-model="borderLength" :data="optionBordersL"></text-selector>
                                            <div class="has-text-info has-text-centered my-3" v-show="!borderLength?.Id">
                                                Select a border length
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </o-collapse>

                        <div class="has-text-centered is-size-2 mt-5">
                            <transition name="fade">
                                <div v-show="result != null">
                                    <b>The open area of your grille is:</b>
                                    <div class="is-flex is-flex-wrap-wrap is-justify-content-space-around">
                                        <div>
                                            <span v-show="openArea >= 0">{{openArea}} in&#178;</span>
                                        </div>
                                        <div>
                                            <span v-show="percentOpen >= 0">{{percentOpen}} %</span>
                                        </div>
                                    </div>
                                </div>
                            </transition>
                        </div>

                        <div class="has-text-centered my-5">
                            <o-button variant="danger" class="mx-2" @click="Reset">Reset</o-button>
                            <o-button variant="primary" icon-left="calculator" class="mx-2" :disabled="!isValid2" @click="Calculate2">Calculate</o-button>
                        </div>
                    </div>
                </o-step-item>
            </o-steps>



        </div>
    </div>
</template>
<script>
    import { getCurrentInstance, ref, computed, watch, onMounted, inject } from "vue";
    import ImageSelector from "@/components/imageSelector.vue";
    import TextSelector from "@/components/textSelector.vue";
    import PatternSelector from "@/components/spec/style/patternSelector.vue";
    export default {
        components: {
            "image-selector": ImageSelector,
            "text-selector": TextSelector,
            "pattern-selector": PatternSelector
        },
        props: {},
        emits: [],
        setup() {
            const global = getCurrentInstance()?.appContext.config.globalProperties;
            const $http = inject("$http");
            const $success = inject("$success");
            const $error = inject("$error");

            const step = ref(0);

            const optionBordersH = ref([]);
            const optionBordersL = ref([]);
            const options = ref([]);
            const style = ref({});
            const patternSize = ref({});
            const length = ref();
            const height = ref();
            const openLength = ref();
            const openHeight = ref();
            const borderHeight = ref({});
            const borderLength = ref({});
            const result = ref(null);

            const openArea = computed(() => {
                return result.value?.OpenArea;
            });
            const percentOpen = computed(() => {
                return result.value?.OpenPercent;
            });

            const subTitleStyle = computed(() => {
                var text = style.value?.Name;
                return text;
            });

            const subTitlePatternSize = computed(() => {
                var text = patternSize.value?.Name;
                return text;
            });

            const subTitleDim = computed(() => {
                var builder = [];
                var text = "";
                if (height.value && height.value >= 0)
                    builder.push(height.value + "\" H");
                if (length.value && length.value >= 0)
                    builder.push(length.value + "\" L");
                text = builder.join(" x ");

                builder = [];
                if (openHeight.value && openHeight.value >= 0)
                    builder.push(openHeight.value + "\" H");
                if (openLength.value && openLength.value >= 0)
                    builder.push(openLength.value + "\" L");
                if (builder.length > 0) {
                    text += " Opening: " + builder.join(" x ");
                }

                return text;
            });

            const subTitleBorder = computed(() => {
                var builder = [];
                var text = "";
                if (borderHeight.value?.Id)
                    builder.push(borderHeight.value.Name + "\" H");
                if (borderLength.value?.Id)
                    builder.push(borderLength.value.Name + "\" L");
                text = builder.join(" x ");
                return text;
            });

            const isValid = computed(() => {
                return !!(style.value?.Id && patternSize.value?.Name && length.value != "" && length.value >= 0 && height.value != "" && height.value >= 0 && openLength.value != "" && openLength.value >= 0 && openHeight.value != "" && openHeight.value >= 0);
            });
            const isValid2 = computed(() => {
                return !!(borderLength.value?.Id && borderHeight.value?.Id);
            });

            const LoadStyles = async () => {
                try {
                    var response = await $http.get("/api/grilleType/" + 2 + "/styles");
                    options.value = response.data;
                }
                catch (err) { options.value = []; }
            };

            const Reset = () => {
                step.value = 1;
                style.value = {};
                patternSize.value = {};
                length.value = "";
                height.value = "";
                openLength.value = "";
                openHeight.value = "";
                borderHeight.value = {};
                borderLength.value = {};
                result.value = null;
            };

            var calc1Response = {};
            const Calculate1 = async () => {
                try {
                    var response = await $http.post("/api/calculator/openarea/perforated/step/1", { style: style.value, patternSize: patternSize.value, length: length.value, height: height.value, openLength: openLength.value, openHeight: openHeight.value });
                    calc1Response = response.data;
                    optionBordersH.value = response.data.BorderHeights.map((x, i) => { return { Id: i + 1, Name: x, Description: "Inches" } });
                    optionBordersL.value = response.data.BorderLengths.map((x, i) => { return { Id: i + 1, Name: x, Description: "Inches" } });
                    step.value = 2;
                }
                catch (err) {
                    $error("Failed to run calculation.");
                    return Promise.reject(err);
                }
            };
            const Calculate2 = async () => {
                try {
                    var response = await $http.post("/api/calculator/openarea/perforated/step/2", {
                        bx: calc1Response.b[0], by: calc1Response.b[1],
                        nx1: calc1Response.nx[0], nx2: calc1Response.nx[1], nx3: calc1Response.nx[2], nx4: calc1Response.nx[3],
                        ny1: calc1Response.ny[0], ny2: calc1Response.ny[1], ny3: calc1Response.ny[2], ny4: calc1Response.ny[3],
                        cellArea: calc1Response.CellArea, grilleArea: calc1Response.GrilleArea, refcode: calc1Response.Refcode,
                        ax1: calc1Response.BorderLengths[0], ax2: calc1Response.BorderLengths[1], ax3: calc1Response.BorderLengths[2], ax4: calc1Response.BorderLengths[3],
                        ay1: calc1Response.BorderHeights[0], ay2: calc1Response.BorderHeights[1], ay3: calc1Response.BorderHeights[2], ay4: calc1Response.BorderHeights[3],
                        borderLength: borderLength.value.Name, borderHeight: borderHeight.value.Name
                    });
                    result.value = response.data;
                }
                catch (err) {
                    $error("Failed to run calculation.");
                    return Promise.reject(err);
                }
            };

            onMounted(async () => {
                await LoadStyles();
            });

            return {
                step,
                optionBordersH,
                optionBordersL,
                options,
                style,
                patternSize,
                length,
                height,
                openLength,
                openHeight,
                borderHeight,
                borderLength,
                result,
                openArea,
                percentOpen,
                subTitleStyle,
                subTitlePatternSize,
                subTitleDim,
                subTitleBorder,
                isValid,
                isValid2,
                LoadStyles,
                Reset,
                Calculate1,
                Calculate2
            };
        }
    }
</script>