<template>
    <div class="section">
        <div class="container">
            <h2 class="title is-1">Weight Calculator</h2>

            <o-collapse class="card my-2" animation="slide">
                <template v-slot:trigger="props" role="button">
                    <div class="card-header">
                        <p class="card-header-title">
                            Material <span class="card-header-subtitle mx-2" v-show="!props.open">{{subTitleMaterial}}</span>
                        </p>
                        <a class="card-header-icon"><o-icon :icon="props.open ? 'caret-up' : 'caret-down'"></o-icon></a>
                    </div>
                </template>
                <div class="card-content">
                    <div class="content">
                        <image-selector v-model="material" type="GrilleMaterialType" :size="8"></image-selector>
                        <div class="has-text-info has-text-centered my-3" v-show="!material?.Id">
                            Select a material
                        </div>
                    </div>
                </div>
            </o-collapse>

            <o-collapse class="card my-2" animation="slide" :open="!!material?.Id">
                <template v-slot:trigger="props" role="button">
                    <div class="card-header">
                        <p class="card-header-title">
                            Frame <span class="card-header-subtitle mx-2" v-show="!props.open">{{subTitleFrame}}</span>
                        </p>
                        <a class="card-header-icon"><o-icon :icon="props.open ? 'caret-up' : 'caret-down'"></o-icon></a>
                    </div>
                </template>
                <div class="card-content">
                    <div class="content">
                        <image-selector v-model="frame" type="GrilleFrameType" :size="8"></image-selector>
                        <div class="has-text-info has-text-centered my-3" v-show="!frame?.Id">
                            Select a frame
                        </div>
                    </div>
                </div>
            </o-collapse>

            <o-collapse class="card my-2" animation="slide" :open="!!frame?.Id">
                <template v-slot:trigger="props" role="button">
                    <div class="card-header">
                        <p class="card-header-title">
                            Dimensions <span class="card-header-subtitle mx-2" v-show="!props.open">{{subTitleDim}}</span>
                        </p>
                        <a class="card-header-icon"><o-icon :icon="props.open ? 'caret-up' : 'caret-down'"></o-icon></a>
                    </div>
                </template>
                <div class="card-content">
                    <div class="content">
                        <div class="columns">
                            <div class="column is-6">
                                <o-field label="Height (in)">
                                    <o-input v-model="height"></o-input>
                                </o-field>
                            </div>
                            <div class="column is-6">
                                <o-field label="Length (in)">
                                    <o-input v-model="length"></o-input>
                                </o-field>
                            </div>
                        </div>
                    </div>
                </div>
            </o-collapse>

            <div class="has-text-centered is-size-2 mt-5">
                <transition name="fade">
                    <div v-show="result >= 0">
                        {{result}} lbs
                    </div>
                </transition>
            </div>
            <div class="has-text-centered my-5">
                <o-button variant="danger" class="mx-2" @click="Reset">Reset</o-button>
                <o-button variant="primary" icon-left="calculator" class="mx-2" :disabled="!isValid" @click="Calculate">Calculate</o-button>
            </div>
        </div>
    </div>
</template>
<script>
    import { getCurrentInstance, ref, computed, watch, onMounted, inject } from "vue";
    import ImageSelector from "@/components/imageSelector.vue";
    export default {
        components: {
            "image-selector": ImageSelector
        },
        props: {},
        emits: [],
        setup() {
            const global = getCurrentInstance()?.appContext.config.globalProperties;
            const $http = inject("$http");
            const $success = inject("$success");
            const $error = inject("$error");

            const length = ref();
            const height = ref();
            const material = ref({});
            const frame = ref({});
            const calculation = ref();

            const result = computed(() => {
                return calculation.value?.toFixed(2);
            });

            const subTitleMaterial = computed(() => {
                var text = material.value?.Name;
                return text;
            });
            const subTitleFrame = computed(() => {
                var text = frame.value?.Name;
                return text;
            });
            const subTitleDim = computed(() => {
                const builder = [];
                var text = "";
                if (height.value && height.value >= 0)
                    builder.push(height.value + "\" H");
                if (length.value && length.value >= 0)
                    builder.push(length.value + "\" L");
                text = builder.join(" x ");
                return text;
            });
            const isValid = computed(() => {
                return !!(material.value?.Id && frame.value?.Id && length.value != "" && length.value >= 0 && height.value != "" && height.value >= 0);
            });

            const Reset = () => {
                material.value = {};
                frame.value = {};
                length.value = "";
                height.value = "";
                calculation.value = undefined;
            };
            const Calculate = async () => {
                try {
                    var response = await $http.post("/api/calculator/weight", { material: material.value, frame: frame.value, length: length.value, height: height.value });
                    calculation.value = response.data;
                }
                catch (err) {
                    $error("Failed to run calculation.");
                    return Promise.reject(err);
                }
            };

            return {
                length,
                height,
                material,
                frame,
                result,
                subTitleMaterial,
                subTitleFrame,
                subTitleDim,
                isValid,
                Reset,
                Calculate,
            };
        }
    }
</script>