<template>
    <div class="section">
        <div class="container">
            <h2 class="title is-1">Air Flow Calculator</h2>
            <p>
                This page is intended to be used with linear bar grilles to quickly and easily determine airflow.
            </p>

            <o-collapse class="card my-2" animation="slide">
                <template v-slot:trigger="props" role="button">
                    <div class="card-header">
                        <p class="card-header-title">
                            Details <span class="card-header-subtitle mx-2" v-show="!props.open">{{subTitle}}</span>
                        </p>
                        <a class="card-header-icon"><o-icon :icon="props.open ? 'caret-up' : 'caret-down'"></o-icon></a>
                    </div>
                </template>
                <div class="card-content">
                    <div class="content">
                        <div class="columns is-multiline">
                            <div class="column is-12">
                                <o-switch v-model="selector">
                                    <span v-show="selector">Calculate FPM</span>
                                    <span v-show="!selector">Calculate CFM</span>
                                </o-switch>
                            </div>
                            <div class="column is-6">
                                <o-field label="Grille Height (in)">
                                    <o-input v-model="height"></o-input>
                                </o-field>
                            </div>
                            <div class="column is-6">
                                <o-field label="Grille Length (in)">
                                    <o-input v-model="length"></o-input>
                                </o-field>
                            </div>
                            <div class="column is-12">
                                <p>
                                    Please give us either CFM (cubic feet per minute) <b>or</b> FPM (feet per minute).
                                </p>
                                <o-field>
                                    <template v-slot:label>
                                        <span v-show="selector">CFM</span>
                                        <span v-show="!selector">FPM</span>
                                    </template>
                                    <template v-slot:message>
                                        <span v-show="selector">cubic feet per minute</span>
                                        <span v-show="!selector">feet per minute</span>
                                    </template>
                                    <template v-slot>
                                        <o-input v-model="cfm" v-show="selector"></o-input>
                                        <o-input v-model="fpm" v-show="!selector"></o-input>
                                    </template>
                                </o-field>
                            </div>
                        </div>
                    </div>
                </div>
            </o-collapse>

            <div class="has-text-centered is-size-2 mt-5">
                <transition name="fade">
                    <div v-if="result?.num != null">
                        <b>{{result.text}}</b>
                        <div class="is-flex is-flex-wrap-wrap is-justify-content-space-around">
                            <div>
                                <p>
                                    {{result.num}} <span class="is-size-3">{{result.units}}</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </transition>
            </div>

            <div class="has-text-centered my-5">
                <o-button variant="danger" class="mx-2" @click="Reset">Reset</o-button>
                <o-button variant="primary" icon-left="calculator" class="mx-2" :disabled="!isValid" @click="Calculate">Calculate</o-button>
            </div>
        </div>
    </div>
</template>
<script>
    import { getCurrentInstance, ref, computed, watch, onMounted, inject } from "vue";
    export default {
        props: {},
        emits: [],
        setup() {
            const global = getCurrentInstance()?.appContext.config.globalProperties;
            const $http = inject("$http");
            const $success = inject("$success");
            const $error = inject("$error");

            const selector = ref(true);
            const length = ref();
            const height = ref();
            const cfm = ref();
            const fpm = ref();
            const result = ref(null);

            const subTitle = computed(() => {
                var builder = [];
                var text = "";
                if (height.value && height.value >= 0) {
                    builder.push(height.value + "\" H");
                }
                if (length.value && length.value >= 0) {
                    builder.push(length.value + "\" L");
                }
                text = builder.join(" x ");

                if (selector.value) {
                    if (cfm.value && cfm.value >= 0) {
                        builder = [" ", "CFM", cfm.value];
                        text += builder.join(" ");
                    }
                }
                else {
                    if (fpm.value && fpm.value >= 0) {
                        builder = [" ", "FPM", fpm.value];
                        text += builder.join(" ");
                    }
                }
                return text;
            });
            const isValid = computed(() => {
                return length.value && height.value && (selector.value && cfm.value || !selector.value && fpm.value);
            });

            const Reset = () => {
                length.value = "";
                height.value = "";
                cfm.value = "";
                fpm.value = "";
                result.value = null;
            };
            const Calculate = async () => {
                result.value = {};
                if (selector.value) {
                    result.value.text = "Based on grille size and known CFM, the FPM is:";
                    result.value.units = "feet per minute";
                }
                else {
                    result.value.text = "Based on grille size and known FPM, the CFM is:";
                    result.value.units = "cubic feet per minute";
                }

                try {
                    var response = await $http.post("/api/calculator/airflow", { length: length.value, height: height.value, selector: selector.value, cfm: cfm.value, fpm: fpm.value });
                    result.value.num = response.data;
                }
                catch (err) {
                    $error("Failed to run calculation.");
                    return Promise.reject(err);
                }
            };

            return {
                selector,
                length,
                height,
                cfm,
                fpm,
                result,
                subTitle,
                isValid,
                Reset,
                Calculate
            };
        }
    }
</script>